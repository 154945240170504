<template>
  <!-- Page Wrapper -->
  <div
    class="modal fade"
    id="exampleModal3"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="modals.filterstatus"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="verifyModalContent_title">
            FILTER BY STATUS
          </h5>
          <button
            class="btn btn-close"
            type="button"
            @click="closeModal('filterstatus')"
            aria-label="Close"
          >
            <i class="fa fa-window-close" aria-hidden="true"></i>
          </button>
        </div>
        <Form @submit="filterByStatus" class="user">
          <div class="modal-body">
            <div class="form-group">
              <label class="col-form-label" for="status">Ticket Status:</label>
              <Field
                name="ticket_status"
                class="form-control form-control-md"
                as="select"
                v-model="selectedStatus"
              >
                <option value="" :selected="selected">---Status---</option>
                <option
                  v-for="status in ticketStatuses"
                  :value="status.id"
                  :key="status.id"
                >
                  {{ status.status_name }}
                </option>
              </Field>
              <ErrorMessage name="status" class="text-danger p-3" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              @click="closeModal('filterstatus')"
            >
              Close
            </button>
            <button class="btn btn-primary" type="Submit">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="exampleModal5"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="modals.filterdepartment"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="verifyModalContent_title">
            FILTER BY DEPARTMENT
          </h5>
          <button
            class="btn btn-close"
            type="button"
            @click="closeModal('filteremployee')"
            aria-label="Close"
          >
            <i class="fa fa-window-close" aria-hidden="true"></i>
          </button>
        </div>
        <Form @submit="filterByDepartment" class="user">
          <div class="modal-body">
            <div class="form-group">
              <label class="col-form-label" for="status">Department:</label>
              <Field
                name="department"
                class="form-control form-control-md"
                as="select"
                v-model="selectedDepartment"
              >
                <option value="" :selected="selectedDepartment">
                  ---Departments---
                </option>
                <option
                  v-for="department in internalDepartments"
                  :value="department.id"
                  :key="department.id"
                >
                  {{ department.department_name }}
                </option>
              </Field>
              <ErrorMessage name="user_id" class="text-danger p-3" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              @click="closeModal('filterdepartment')"
            >
              Close
            </button>
            <button class="btn btn-primary" type="Submit">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="exampleModal6"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="modals.filtercategory"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="verifyModalContent_title">
            FILTER BY CATEGORY
          </h5>
          <button
            class="btn btn-close"
            type="button"
            @click="closeModal('filtercategory')"
            aria-label="Close"
          >
            <i class="fa fa-window-close" aria-hidden="true"></i>
          </button>
        </div>
        <Form @submit="filterByCategory" class="user">
          <div class="modal-body">
            <div class="form-group">
              <label class="col-form-label" for="status">Category:</label>
              <Field
                name="category"
                class="form-control form-control-md"
                as="select"
                v-model="selectedCategory"
              >
                <option value="" :selected="selected">---Category---</option>
                <option
                  v-for="category in ticketCategories"
                  :value="category.id"
                  :key="category.id"
                >
                  {{ category.category_name }}
                </option>
              </Field>
              <div action="/target" id="my-dropzone" class="dropzone"></div>
              <ErrorMessage name="user_id" class="text-danger p-3" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              @click="closeModal('filtercategory')"
            >
              Close
            </button>
            <button class="btn btn-primary" type="Submit">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="exampleModal4"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="modals.filterpriority"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="verifyModalContent_title">
            FILTER BY PRIORITY
          </h5>
          <button
            class="btn btn-close"
            type="button"
            @click="closeModal('filteremployee')"
            aria-label="Close"
          >
            <i class="fa fa-window-close" aria-hidden="true"></i>
          </button>
        </div>
        <Form @submit="filterByPriority" class="user">
          <div class="modal-body">
            <div class="form-group">
              <label class="col-form-label" for="status">Priority:</label>
              <Field
                name="priority"
                class="form-control form-control-md"
                as="select"
                v-model="selectedPriority"
              >
                <option value="" :selected="selected">---Priorities---</option>
                <option
                  v-for="priority in ticketPriorities"
                  :value="priority.id"
                  :key="priority.id"
                >
                  {{ priority.priority_name }}
                </option>
              </Field>
              <ErrorMessage name="user_id" class="text-danger p-3" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              @click="closeModal('filterpriority')"
            >
              Close
            </button>
            <button class="btn btn-primary" type="Submit">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="exampleModal7"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="modals.assignemployee"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="verifyModalContent_title">
            Assign an Employee
          </h5>
          <button
            class="btn btn-close"
            type="button"
            @click="closeModal('filteremployee')"
            aria-label="Close"
          >
            <i class="fa fa-window-close" aria-hidden="true"></i>
          </button>
        </div>
        <Form @submit="filterByEmployee" class="user">
          <div class="modal-body">
            <div class="form-group">
              <label class="col-form-label" for="status">Employee:</label>
              <Field
                name="employees"
                class="form-control form-control-md"
                as="select"
                v-model="selectedEmployee"
              >
                <option value="" :selected="selected">---Employees---</option>
                <option
                  v-for="employee in employees"
                  :value="employee.id"
                  :key="employee.id"
                >
                  {{ employee.first_name }} {{ employee.last_name }}
                </option>
              </Field>
              <ErrorMessage name="user_id" class="text-danger p-3" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              @click="closeModal('assignemployee')"
            >
              Close
            </button>
            <button class="btn btn-primary" type="Submit">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="exampleModal2"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="modals.filteremployee"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="verifyModalContent_title">
            FILTER BY EMPLOYEE
          </h5>
          <button
            class="btn btn-close"
            type="button"
            @click="closeModal('filteremployee')"
            aria-label="Close"
          >
            <i class="fa fa-window-close" aria-hidden="true"></i>
          </button>
        </div>
        <Form @submit="filterByEmployee" class="user">
          <div class="modal-body">
            <div class="form-group">
              <label class="col-form-label" for="status">Employee:</label>
              <Field
                name="employees"
                class="form-control form-control-md"
                as="select"
                v-model="selectedEmployee"
              >
                <option value="" :selected="selected">---Employees---</option>
                <option
                  v-for="employee in employees"
                  :value="employee.id"
                  :key="employee.id"
                >
                  {{ employee.first_name }} {{ employee.last_name }}
                </option>
              </Field>
              <ErrorMessage name="user_id" class="text-danger p-3" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              @click="closeModal('filteremployee')"
            >
              Close
            </button>
            <button class="btn btn-primary" type="Submit">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div id="wrapper">
    <SideBar />
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <Topbar />
        <!-- Begin Page Content -->
        <div class="container-fluid px-5 mt-5">
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-2"
          >
            <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
          </div>
          <div class="text-muted mb-3">Ticket and Project Summary</div>

          <div class="row">
            <div class="col-xl-12 col-md-12 mb-4">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    TICKET INSIGHTS
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-4 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-info text-uppercase mb-1"
                              >
                                Open
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                <!-- {{ this.onlineTransactionsInsights.count }} -->
                                {{ this.openCount }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i
                                class="fas fa-sort-numeric-up fa-2x text-gray-300"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-info text-uppercase mb-1"
                              >
                                Inprogress
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                {{ this.inprogCount }}
                                <!-- {{ this.onlineTransactionsInsights.withinSLA }} -->
                              </div>
                            </div>
                            <div class="col-auto">
                              <i
                                class="fas fa-sort-numeric-up fa-2x text-gray-300"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-4 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-info text-uppercase mb-1"
                              >
                                Closed
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                <!-- $ {{ this.onlineTransactionsInsights.unInvoicedAmount }} -->
                                {{ this.closedCount }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i
                                class="fas fa-money-bill fa-2x text-gray-300"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-md-12 mb-4">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    PROJECT INSIGHTS
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-4 col-md-6 mb-4">
                      <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                              >
                                INITIATED
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                {{ this.projectOpenCount }}
                                <!-- {{
                                  this.scannedInvoiceTransactionsInsights.count
                                }} -->
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-file fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 mb-4">
                      <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                              >
                                INPROGRESS
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                <!-- {{
                                  this.scannedInvoiceTransactionsInsights
                                    .outsideSLA
                                }} -->
                                {{ this.projectInprogCount }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i
                                class="fas fa-sort-numeric-up fa-2x text-gray-300"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 mb-4">
                      <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                              >
                                COMPLETED
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                <!-- {{
                                  this.scannedInvoiceTransactionsInsights
                                    .withinSLA
                                }} -->
                                {{ this.projectClosedCount }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i
                                class="fas fa-sort-numeric-up fa-2x text-gray-300"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Content Row -->
          <div class="row">
            <div class="col-xl-12 col-lg-12 mb-2">
              <div class="card shadow">
                <div class="card-header d-flex justify-content-between py-3">
                  <div class="d-flex align-items-center pb-1 gap-2">
                    <span class="red-dot-with-ripple"></span>
                    <p class="h6 m-0 font-weight-bold text-primary">
                      Tickets Aging
                    </p>
                  </div>
                  <div class="dropdown no-arrow d-flex gap-2">
                    <a
                      class="dropdown-toggle pl-3"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Filter"
                    >
                      <i class="fas fa-filter mt-2 text-info"></i>
                    </a>

                    <div
                      class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <!-- <a
                          class="dropdown-item border-radius-md"
                          data-toggle="modal"
                          @click="openModal('filteredate')"
                          data-target="#exampleModal"
                          ><i class="fa fa-smile-o" aria-hidden="true"></i>
                          Date</a
                        > -->
                      <a
                        v-if="this.isAdmin"
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#exampleModal2"
                        @click="openModal('filteremployee')"
                      >
                        <i
                          class="fa fa-file-pdf-o icon2"
                          aria-hidden="true"
                        ></i>
                        Assigned</a
                      >
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#exampleModal3"
                        @click="openModal('filterstatus')"
                      >
                        <i
                          class="fa fa-file-pdf-o icon2"
                          aria-hidden="true"
                        ></i>
                        Status</a
                      >
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#exampleModal4"
                        @click="openModal('filterpriority')"
                      >
                        <i
                          class="fa fa-file-pdf-o icon2"
                          aria-hidden="true"
                        ></i>
                        Priority</a
                      >
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#exampleModal5"
                        @click="openModal('filterdepartment')"
                      >
                        <i
                          class="fa fa-file-pdf-o icon2"
                          aria-hidden="true"
                        ></i>
                        Department</a
                      >
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#exampleModal6"
                        @click="openModal('filtercategory')"
                      >
                        <i
                          class="fa fa-file-pdf-o icon2"
                          aria-hidden="true"
                        ></i>
                        Category</a
                      >
                      <div class="dropdown-divider"></div>
                      <a
                        class="dropdown-item border-radius-md text-danger"
                        href="javascript:;"
                        @click="NoFilter()"
                        >Remove Filter</a
                      >
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row mt-lg-4 mt-2" v-if="cardArray.length > 0">
                    <div
                      class="col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4"
                      v-for="ticket in cardArray"
                      v-bind:key="ticket.id"
                    >
                      <div
                        :class="[
                          'card card-hover rounded-sm shadow-lg',
                          ticket.category.category_name ===
                          'Transponder Requests'
                            ? 'border-left-custom'
                            : 'border-left-danger',
                        ]"
                        style="border-radius: 8px"
                      >
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col-auto pr-4">
                              <img
                                v-if="
                                  ticket.category.category_name ===
                                  'Transponder Requests'
                                "
                                class="text-center imgcustom"
                                style="height: 70px; width: 70px"
                                :src="require('@/assets/transponderRec.png')"
                                alt="LOGO"
                              />
                              <img
                                v-else
                                class="text-center imgcustom"
                                style="height: 70px; width: 70px"
                                :src="require('@/assets/ticket.png')"
                                alt="LOGO"
                              />
                            </div>
                            <div class="col">
                              <div class="d-flex justify-content-between">
                                <div
                                  :class="[
                                    'font-weight-bold  text-uppercase mb-1',
                                    ticket.category.category_name ===
                                    'Transponder Requests'
                                      ? 'text-custom'
                                      : 'text-danger',
                                  ]"
                                  class=""
                                  style="font-size: small"
                                >
                                  {{ ticket.ticket_number }}
                                </div>
                                <div class="text-uppercase">
                                  <span
                                    v-if="
                                      ticket.ticket_status.status_name ===
                                      'Open'
                                    "
                                    class="badge badge-pill text-uppercase font-weight-bold"
                                    style="
                                      background-color: #d6fcd6;
                                      color: #5cb85c;
                                      border: 1px solid #8ae08a;
                                      font-size: x-small;
                                      width: 90px;
                                      display: inline-block;
                                    "
                                  >
                                    {{ ticket.ticket_status.status_name }}
                                  </span>
                                  <span
                                    v-if="
                                      ticket.ticket_status.status_name ===
                                      'Assigned'
                                    "
                                    class="badge badge-pill text-uppercase font-weight-bold"
                                    style="
                                      background-color: #fff3cd;
                                      color: #c9ae68;
                                      border: 1px solid #ffc107;
                                      font-size: x-small;
                                      width: 90px;
                                      display: inline-block;
                                    "
                                  >
                                    {{ ticket.ticket_status.status_name }}
                                  </span>
                                  <span
                                    v-if="
                                      ticket.ticket_status.status_name ===
                                      'In progress'
                                    "
                                    class="badge badge-pill text-uppercase font-weight-bold"
                                    style="
                                      background-color: #bfd4f9;
                                      color: #5999d4;
                                      border: 1px solid #5999d4;
                                      font-size: x-small;
                                      display: inline-block;
                                    "
                                  >
                                    {{ ticket.ticket_status.status_name }}
                                  </span>
                                  <span
                                    v-if="
                                      ticket.ticket_status.status_name ===
                                      'Closed'
                                    "
                                    class="badge badge-pill text-uppercase font-weight-bold"
                                    style="
                                      background-color: #e3dcf9;
                                      color: #8a7cff;
                                      border: 1px solid #8a7cff;
                                      font-size: x-small;
                                      width: 90px;
                                      display: inline-block;
                                    "
                                  >
                                    {{ ticket.ticket_status.status_name }}
                                  </span>
                                  <span
                                    v-if="
                                      ticket.ticket_status.status_name ===
                                      'Complete'
                                    "
                                    class="badge badge-pill text-uppercase font-weight-bold"
                                    style="
                                      background-color: #e3dcf9;
                                      color: #8a7cff;
                                      border: 1px solid #8a7cff;
                                      font-size: x-small;
                                      width: 90px;
                                      display: inline-block;
                                    "
                                  >
                                    {{ ticket.ticket_status.status_name }}
                                  </span>
                                </div>
                              </div>
                              <div
                                class="text-gray-800 font-weight-bold mb-1"
                                style="font-size: small"
                              >
                                {{ ticket.title }}
                              </div>
                              <div class="d-flex gap-2 pt-2 text-uppercase">
                                <span
                                  v-if="
                                    ticket.priority.priority_name === 'High'
                                  "
                                  class="text-xs"
                                >
                                  <i class="fa fa-angle-up text-danger"></i>
                                  <span class="font-weight-bold ml-1">{{
                                    ticket.priority.priority_name
                                  }}</span>
                                </span>
                                <span
                                  v-if="ticket.priority.priority_name === 'Low'"
                                  class="text-xs"
                                >
                                  <i
                                    class="fa fa-chevron-down text-primary"
                                  ></i>
                                  <span class="font-weight-bold ml-1">{{
                                    ticket.priority.priority_name
                                  }}</span>
                                </span>
                                <span
                                  v-if="
                                    ticket.priority.priority_name === 'Medium'
                                  "
                                  class="text-xs"
                                >
                                  <i class="fa fa-equals text-warning"></i>
                                  <span class="font-weight-bold ml-1">{{
                                    ticket.priority.priority_name
                                  }}</span>
                                </span>
                              </div>
                              <div
                                class="d-flex justify-content-between align-items-center mt-2"
                              >
                                <div class="d-flex align-items-center">
                                  <p
                                    class="text-gray-600 mb-0 mr-2"
                                    style="font-size: small"
                                  >
                                    Assigned To:
                                  </p>
                                  <p
                                    class="font-weight-bold mb-0"
                                    style="font-size: small"
                                  >
                                    <span v-if="ticket.assigned === null"
                                      >Not Assigned</span
                                    >
                                    <span v-else
                                      >{{ ticket.assigned.first_name }}
                                      {{ ticket.assigned.last_name }}</span
                                    >
                                  </p>
                                </div>
                                <div class="d-flex">
                                  <button
                                    class="btn btn-xs btn-outline-primary"
                                    @click="viewTicket(ticket)"
                                  >
                                    <i
                                      class="fas fa-eye"
                                      style="font-size: small"
                                    ></i>
                                  </button>
                                  <!-- Uncomment and adjust the following block if admin functionality is needed -->
                                  <!-- <button v-if="isAdmin" class="btn btn-xs btn-outline-info" @click="openModal('assignemployee')">
                <i class="fas fa-wrench text-info" style="font-size: small;"></i>
              </button> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pt-4 mb-4" v-else>
                    <div class="card shadow h-100 py-2">
                      <div class="card-body">
                        <h5 class="text-center mb-0">
                          <img
                            class="img-fluid px-3 px-sm-4 mb-2"
                            style="width: 15rem"
                            src="https://st2.depositphotos.com/3223811/9500/i/380/depositphotos_95000756-stock-photo-3d-yellow-ticket.jpg"
                          /><br />No Ticket!
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      @click="paginateCard('Previous')"
                      :disabled="
                        currentPageNumber === 0 || cardArray.length === 0
                      "
                      class="custom-button"
                    >
                      ← Previous
                    </button>
                    <span style="margin: 0 10px">
                      <span v-if="this.cardArray.length === 0">
                        {{ this.offset }}
                      </span>
                      <span v-else>{{ this.offset + 1 }}</span> to
                      {{
                        Math.min(
                          this.offset + this.limit,
                          this.result?.turnOverTickets.count
                        )
                      }}
                      of
                      {{ this.result?.turnOverTickets.count }}
                      entries</span
                    >
                    <button
                      @click="paginateCard('Next')"
                      :disabled="
                        currentPageNumber === totalPages - 1 ||
                        cardArray.length === 0
                      "
                      class="custom-button"
                    >
                      Next →
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-xl-12 col-lg-12 mb-4">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    {{ year }} Summary - Transaction Count and Amount Per agency
                    per Month
                  </h6>
                </div>
                <div class="card-body">
                  <div class="chart-container">
                    <canvas
                      id="barChart"
                      style="display: block; width: 432px; height: 800px"
                      width="432"
                      height="245"
                    ></canvas>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- </div> -->
            <!-- <div class="row"> -->

            <!-- <pre v-else>{{ result }}</pre> -->
          </div>

          <!-- /.container-fluid -->
        </div>
      </div>
      <!-- End of Main Content -->
      <Footer />
      <!-- End of Content Wrapper -->
    </div>
  </div>
  <!-- End of Page Wrapper -->
</template>
<style>
.custom-button {
  border-radius: 5px;
  background-color: #224abe;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
}
.border-left-custom {
  border-left: 4px solid #1eacbf !important ;
}
.text-custom {
  color: #1eacbf;
}
.custom-button:disabled {
  opacity: 0.7;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: url("https://cdn-icons-png.flaticon.com/512/2942/2942934.png")
    no-repeat center center; /* Add your image URL here */
  background-size: cover; /* Ensure the image covers the entire dot */
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  left: 20px;
  width: 25px;
  height: 25px;
  z-index: 400;
}
.card-hover {
  transition: transform 0.3s ease-in-out;
}
.card-hover:hover {
  transform: translateY(-10px);
}
.red-dot-with-ripple {
  position: relative;
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: red;
  animation: ripple 1.5s infinite ease-out;
  position: relative;
  bottom: 2px;
}
@keyframes ripple {
  0% {
    transform: scale(0.2); /* Start with smaller scale */
    opacity: 1;
  }
  100% {
    transform: scale(0.8); /* End with slightly larger scale */
    opacity: 0;
  }
}
</style>
<script>
// @ is an alias to /src
import Topbar from "@/components/common/Topbar.vue";
import Footer from "@/components/common/Footer.vue";
import SideBar from "@/components/common/Navbar.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.css";
import "@/assets/vendor/datatables/jquery.dataTables.min.js";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";

import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { useQuery } from "@vue/apollo-composable";
import pdfMake from "pdfmake/build/pdfmake";
import { computed } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as yup from "yup";
import {
  TURNOVER_QUERY,
  STATUS_QUERY,
  ALL_TICKETS_QUERY,
  PRIORITY_QUERY,
  TICKETS_CATEGORY_QUERY,
  INTERNAL_DEPARTMENTS_QUERY,
  GET_PROJECT,
  ALL_EMPLOYEES_QUERY,
} from "@/graphql";

export default {
  name: "Dashboard",
  components: {
    Topbar,
    Footer,
    SideBar,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const { result, loading, error, refetch, fetchMore } = useQuery(
      TURNOVER_QUERY,
      {
        offset: 0,
        limit: 10,
      }
    );
    const TURNOVERAll = computed(() => result.value?.turnOverTickets);

    const TURNOVER = computed(() => result.value?.turnOverTickets?.data);
    return {
      message: "",
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      user_id: "",
      role: "",
      srtsPrioritycount: 0,
      turnOverAssignedScans: [],
      dailyScansTotal: [],
      myLineChart: null,
      // myDoughnutChart: null,
      progressbar: null,
      myBarChart: null,
      domartSrtAgencies: [],
      onlineTransactionsInsights: [],
      scannedInvoiceTransactionsInsights: [],
      result,
      loading,
      error,
      refetch,
      fetchMore,
      TURNOVERAll,
      TURNOVER,
      cardArray: [],
      offset: 0,
      limit: 10,
      currentPageNumber: 0,
      pageLength: 10,
      totalPages: 0,
      modals: {
        filteredate: false,
        filterstatus: false,
        filterdepartment: false,
        filterpriority: false,
        filteremployee: false,
        assignemployee: false,
        filtercategory: false,
      },
    };
  },

  apollo: {
    ticketStatuses: {
      query: STATUS_QUERY,
      variables: {
        limit: 10,
        offset: 0,
      },
    },
    createTicket: {
      query: ALL_TICKETS_QUERY,
      variables: {
        limit: 10,
        offset: 0,
      },
    },
    employees: {
      query: ALL_EMPLOYEES_QUERY,
    },
    ticketPriorities: {
      query: PRIORITY_QUERY,
      variables: {
        limit: 100,
        offset: 0,
      },
    },
    ticketCategories: {
      query: TICKETS_CATEGORY_QUERY,
      variables: {
        limit: 10,
        offset: 0,
      },
    },
    internalDepartments: {
      query: INTERNAL_DEPARTMENTS_QUERY,
      variables: {
        limit: 10,
        offset: 0,
      },
    },
    projects: {
      query: GET_PROJECT,
      variables: {
        limit: 50,
        offset: 0,
      },
    },
  },
  methods: {
    async fetchInprogProjectCount() {
      try {
        const response = await this.$apollo.query({
          query: GET_PROJECT,
          variables: {
            limit: 10,
            offset: 0,
            filter: {
              status: "1",
            },
          },
        });
        const InprogProject = response.data.projects.count;
        this.projectInprogCount = InprogProject;
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    },
    async fetchCloseProjectCount() {
      try {
        const response = await this.$apollo.query({
          query: GET_PROJECT,
          variables: {
            limit: 10,
            offset: 0,
            filter: {
              status: "2",
            },
          },
        });
        const ClosedProject = response.data.projects.count;
        this.projectClosedCount = ClosedProject;
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    },
    async fetchRejectsProjectCount() {
      try {
        const response = await this.$apollo.query({
          query: GET_PROJECT,
          variables: {
            limit: 10,
            offset: 0,
            filter: {
              status: "-1",
            },
          },
        });
        const RejectProject = response.data.projects.count;
        this.projectRejCount = RejectProject;
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    },
    async fetchOpenProjectCount() {
      try {
        const response = await this.$apollo.query({
          query: GET_PROJECT,
          variables: {
            limit: 10,
            offset: 0,
            filter: {
              status: "0",
            },
          },
        });
        const OpenProject = response.data.projects.count;
        this.projectOpenCount = OpenProject;
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    },
    async fetchOpenTicketsCount() {
      try {
        const response = await this.$apollo.query({
          query: ALL_TICKETS_QUERY,
          variables: {
            limit: 10,
            offset: 0,
            filter: {
              ticket_status: 1,
            },
          },
        });
        const OpenTicket = response.data.tickets.count;
        this.openCount = OpenTicket;
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    },
    async fetchInprogTicketsCount() {
      try {
        const response = await this.$apollo.query({
          query: ALL_TICKETS_QUERY,
          variables: {
            limit: 10,
            offset: 0,
            filter: {
              ticket_status: 3,
            },
          },
        });
        const InprogTicket = response.data.tickets.count;
        this.inprogCount = InprogTicket;
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    },
    async fetchClosedTicketsCount() {
      try {
        const response = await this.$apollo.query({
          query: ALL_TICKETS_QUERY,
          variables: {
            limit: 10,
            offset: 0,
            filter: {
              ticket_status: 2,
            },
          },
        });
        const ClosedTicket = response.data.tickets.count;
        this.closedCount = ClosedTicket;
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    },
    viewTicket(ticket) {
      const ticketId = ticket.id;
      const ticketNum = ticket.ticket_number;
      this.$router.push({
        name: "View Ticket",
        params: { id: ticketId, ticket_num: ticketNum },
      });
      localStorage.setItem("ticket", JSON.stringify(ticketId));
    },
    async statusChange() {
      const self = this;
      await self.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {},
      });

      var columns = [
        // {
        //   data: null,
        //   render: function (data, type, row) {
        //     return (
        //       '<input type="checkbox" class="select-checkbox" data-scan-id="' +
        //       row.scan_id +
        //       '" data-row="' +
        //       JSON.stringify(row) +
        //       '">'
        //     );
        //   },
        // },
        { data: "ticket_number" },
        {
          data: "creator",
          render: function (data, type, row) {
            if (type === "display" || type === "filter") {
              return data
                ? `${data.first_name
                    .toUpperCase()
                    .replace(/_/g, " ")} ${data.last_name
                    .toUpperCase()
                    .replace(/_/g, " ")}`
                : "";
            }
            return data;
          },
        },
        { data: "title" },
        {
          data: "priority",
          render: function (data, type, row) {
            if (type === "display" || type === "filter") {
              if (data) {
                if (data.priority_name === "High") {
                  return `<span class="gap-2 text-xs pr-2"><i class="fa fa-angle-up text-danger"></i>&nbsp${data.priority_name.toUpperCase()}</span>`;
                } else if (data.priority_name === "Medium") {
                  return `<span class="gap-2 text-xs pr-2"><i class="fa fa-equals" style="color: #ffc107"></i>&nbsp${data.priority_name.toUpperCase()}</span>`;
                } else if (data.priority_name === "Low") {
                  return `<span class="gap-2 text-xs pr-2"><i class="fa fa-chevron-down"
                          style="color: #0275d8"></i>&nbsp${data.priority_name.toUpperCase()}</span>`;
                }
              } else {
                return "-";
              }
            }
            return data;
          },
        },
        // {
        //   data: "ticket_status",
        //   render: function (data, type, row) {
        //     if (type === "display" || type === "filter") {
        //       if (data) {
        //         if (data.ticket_status === "Open") {
        //           return  `<span style="padding: 3px 8px; border: 1px solid #8ae08a; background-color: #d6fcd6; color: #5cb85c; border-radius: 20px; width: 90px; display: inline-block;" class="text-xs w-full font-weight-bold badge badge-pill badge-sm text-xxs">${data.status_name.toUpperCase()}</span>`
        //         } else if (data.ticket_status === "In progress") {
        //           return `<span style="padding: 3px 8px; border: 1px solid #8ae08a; background-color: #d6fcd6; color: #5cb85c; border-radius: 20px; width: 90px; display: inline-block;" class="text-xs w-full font-weight-bold badge badge-pill badge-sm text-xxs">${data.status_name.toUpperCase()}</span>`
        //         } else if (data.ticket_status === "Closed") {
        //           return `<span style="padding: 3px 8px; border: 1px solid #8ae08a; background-color: #d6fcd6; color: #5cb85c; border-radius: 20px; width: 90px; display: inline-block;" class="text-xs w-full font-weight-bold badge badge-pill badge-sm text-xxs">${data.status_name.toUpperCase()}</span>`;
        //         }
        //       } else {
        //         return "-";
        //       }
        //     }
        //     return data;
        //   },
        // },

        {
          data: "ticket_status",
          render: function (data, type, row) {
            if (type === "display" || type === "filter") {
              if (data) {
                let statusColor = "";
                switch (data.status_name) {
                  case "Open":
                    statusColor =
                      "background-color: #d6fcd6; color: #5cb85c; border: 1px solid #8ae08a;";
                    break;
                  case "Assigned":
                    statusColor =
                      "background-color: #fff3cd; color: #c9ae68; border: 1px solid #ffc107;";
                    break;
                  case "In progress":
                    statusColor =
                      "background-color: #bfd4f9; color: #5999d4; border: 1px solid #5999d4;";
                    break;
                  case "Closed":
                    statusColor =
                      "background-color: #e3dcf9; color: #8a7cff; border: 1px solid #8a7cff;";
                    break;
                  default:
                    statusColor = "";
                    break;
                }
                return `<span style="padding: 3px 8px; font-size:x-small; border-radius: 20px; width: 90px; display: inline-block; ${statusColor}" class="text-xs w-full font-weight-bold badge badge-pill badge-sm text-xxs">${data.status_name.toUpperCase()}</span>`;
              } else {
                return "-";
              }
            }
            return data;
          },
        },

        { data: "createdAt" },

        {
          data: null,
          render: function (data, type, row) {
            if (type === "display") {
              var actionsHtml =
                '<td class="d-flex justify-content-center gap-3">' +
                '<div class="dropdown no-arrow dropup">' +
                '<a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                '<i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>' +
                "</a>" +
                '<div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">' +
                '<div class="dropdown-header">Action</div>' +
                '<a class="dropdown-item view" data-row=\'' +
                JSON.stringify(row) +
                '\' href="javascript:;" title="Assign Ticket">' +
                '<div class="d-flex align-items-center viewTicketTable">' +
                '<i class="fas fa-eye pr-2 text-primary"></i>' +
                '<span class="">View</span>' +
                "</div>" +
                "</a>" +
                "</div>" +
                "</div>" +
                "</td>";

              actionsHtml += "</div>" + "</div>" + "</td>";

              return actionsHtml;
            }

            return data;
          },
        },
      ];
      var ex_col = [0, 1, 2, 3, 4, 5];
      await self.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {},
      });

      var ticketData = {
        recordsTotal: self?.result?.turnOverTickets?.count,
        data: self?.result?.turnOverTickets?.data,
        recordsFiltered: self?.result?.turnOverTickets?.count,
      };
      this.cardArray = self?.result?.turnOverTickets?.data;

      function arraysAreEqual(arr1, arr2) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
      }

      var dataTable = $("#srt").DataTable({
        destroy: true,
        lengthChange: true,
        lengthMenu: [10, 50, 100, 150, 200],
        columns: columns,
        processing: self.loading,
        pagingType: "full",
        paging: true,
        info: true,
        serverSide: true,
        fixedHeader: true,
        ajax: function (data, callback, settings) {
          callback(ticketData);
        },

        dom: "Blfrtip",
        buttons: [
          {
            extend: "csv",
            text: '<i class="fa fa-file-excel"></i>',
            className: "btn btn-sm btn-outline-info mb-3 text-success",
            action: function newexportaction(e, dt, button, config) {
              fetchData(0, srtData.recordsTotal).then(() => {
                var self = this;
                var oldStart = dt.settings()[0]._iDisplayStart;
                dt.one("preXhr", function (e, s, data) {
                  data.start = 0;
                  data.length = 2147483647;

                  dt.one("preDraw", function (e, settings) {
                    if (button[0].className.indexOf("buttons-csv") >= 0) {
                      $.fn.dataTable.ext.buttons.csvHtml5.available(dt, config)
                        ? $.fn.dataTable.ext.buttons.csvHtml5.action.call(
                            self,
                            e,
                            dt,
                            button,
                            config
                          )
                        : $.fn.dataTable.ext.buttons.csvFlash.action.call(
                            self,
                            e,
                            dt,
                            button,
                            config
                          );
                    }
                    dt.one("preXhr", function (e, s, data) {
                      settings._iDisplayStart = oldStart;
                      data.start = oldStart;
                    });
                    setTimeout(dt.ajax.reload, 0);

                    return false;
                  });
                });
              });
            },
          },
          {
            extend: "pdf",
            text: '<i class="fa fa-file-pdf"></i>',
            className: "btn btn-sm btn-outline-info mb-3 text-danger",
            action: function newexportaction(e, dt, button, config) {
              // Open Loader
              fetchData(0, srtData.recordsTotal).then(() => {
                var self = this;
                var oldStart = dt.settings()[0]._iDisplayStart;
                dt.one("preXhr", function (e, s, data) {
                  data.start = 0;
                  data.length = 2147483647;

                  dt.one("preDraw", function (e, settings) {
                    if (button[0].className.indexOf("buttons-pdf") >= 0) {
                      $.fn.dataTable.ext.buttons.pdfHtml5.available(dt, config)
                        ? $.fn.dataTable.ext.buttons.pdfHtml5.action.call(
                            self,
                            e,
                            dt,
                            button,
                            config
                          )
                        : $.fn.dataTable.ext.buttons.pdfFlash.action.call(
                            self,
                            e,
                            dt,
                            button,
                            config
                          );
                    }
                    dt.one("preXhr", function (e, s, data) {
                      settings._iDisplayStart = oldStart;
                      data.start = oldStart;
                    });

                    return false;
                  });
                });
              });
            },
          },
          {
            extend: "print",
            text: '<i class="fa fa-print"></i>',
            className: "btn btn-sm btn-outline-primary mb-3 text-secondary",
            action: function newexportaction(e, dt, button, config) {
              // Open Loader
              fetchData(0, srtData.recordsTotal).then(() => {
                var self = this;
                var oldStart = dt.settings()[0]._iDisplayStart;
                dt.one("preXhr", function (e, s, data) {
                  data.start = 0;
                  data.length = 2147483647;
                  dt.one("preDraw", function (e, settings) {
                    if (button[0].className.indexOf("buttons-print") >= 0) {
                      $.fn.dataTable.ext.buttons.print.action(
                        e,
                        dt,
                        button,
                        config
                      );
                    }
                    dt.one("preXhr", function (e, s, data) {
                      settings._iDisplayStart = oldStart;
                      data.start = oldStart;
                    });
                    setTimeout(dt.ajax.reload, 0);

                    return false;
                  });
                });
              });
            },
          },
          {
            extend: "colvis",
            text: '<i class="fa fa-eye"></i>',
            className: "btn btn-sm btn-outline-secondary mb-3 text-primary",
          },
        ],

        language: {
          oPaginate: {
            sNext: '<i class="fa fa-forward"></i>',
            sPrevious: '<i class="fa fa-backward"></i>',
            sFirst: '<i class="fa fa-step-backward"></i>',
            sLast: '<i class="fa fa-step-forward"></i>',
          },
        },
      });
      // dataTable.on("click", ".view", this.viewTicketTable.bind(this));

      async function fetchData(offset, length) {
        self.fetchMore({
          variables: {
            offset: offset,
            limit: length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            let newData = fetchMoreResult?.turnOverTickets?.data;

            let newDataRecords = fetchMoreResult?.turnOverTickets?.count;

            if (
              !arraysAreEqual(
                newData,
                ticketData.data,

                newDataRecords,
                ticketData.recordsTotal,
                ticketData.recordsFiltered
              )
            ) {
              ticketData.data = newData;
              ticketData.recordsTotal = newDataRecords;
              dataTable.clear().rows.add(newData).draw(false);
            }
          },
        });
      }

      dataTable.on("draw.dt", function () {
        var pageInfo = dataTable.page.info();

        var offset = pageInfo.page * pageInfo.length;
        var length = pageInfo.length; // Get the length from the current page info
        this.offset = offset;
        this.limit = length;

        fetchData(offset, length);
      });
      $("#srt").on("change", ".select-checkbox", (event) => {
        // Handle checkbox change event
        try {
          var scanId = $(event.currentTarget).data("scan-id");
          if ($(event.currentTarget).prop("checked")) {
            this.selectedScanIds.push(scanId);
          } else {
            this.selectedScanIds = this.selectedScanIds.filter(
              (id) => id !== scanId
            );
          }
        } catch (error) {
          console.error("Error handling checkbox change:", error);
        }
      });
      $("#srt").on("click", ".work-on", (event) => {
        var rowData = $(event.currentTarget).data("id");

        this.workonSrt(rowData);
      });
      $("#srt").on("click", ".downloadscan", (event) => {
        var rowData = $(event.currentTarget).data("id");

        this.downloadDailyScan(rowData);
      });
      $("#srt").on("click", ".assignScanClass", (event) => {
        var rowData = $(event.currentTarget).data("row");

        this.ReassignemployeeModal(rowData);
      });
      $("#srt").on("click", ".view", (event) => {
        var rowData = $(event.currentTarget).data("row");

        this.viewTicketTable(rowData);
      });
      $("#srt").on("click", ".rollback", (event) => {
        var rowData = $(event.currentTarget).data("id");

        this.rollBackSrt(rowData);
      });

      this.totalPages = Math.ceil(
        self?.result?.turnOverTickets?.count / this.pageLength
      );
    },
    async paginateCard(buttonClicked) {
      if (buttonClicked === "Next") {
        this.currentPageNumber += 1;
      } else if (buttonClicked === "Previous") {
        this.currentPageNumber -= 1;
      } else if (buttonClicked === "First") {
        this.currentPageNumber = 1;
      } else if (buttonClicked === "Last") {
        this.currentPageNumber = this.totalPages;
      }

      function arraysAreEqual(arr1, arr2) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
      }

      var offset = this.currentPageNumber * this.pageLength;
      var length = this.pageLength;
      this.offset = offset;
      this.limit = length;
      this.fetchMore({
        variables: {
          offset: offset,
          limit: length,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;
          let newData = fetchMoreResult?.turnOverTickets?.data;
          if (!arraysAreEqual(newData, this.cardArray)) {
            this.cardArray = newData;
          }
        },
      });
    },
    openModal(modalName) {
      this.modals[modalName] = true;
    },
    closeModal(modalName) {
      (this.statusSrt = ""),
        (this.selectedStatus = ""),
        (this.modals[modalName] = false);
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.parentNode.removeChild(modalBackdrop);
      }
    },
    async filterByEmployee() {
      var self = this;
      this.refetch({
        filter: { assigned: parseInt(this.selectedEmployee) },
      });

      self.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          var ticketData = {
            recordsTotal: fetchMoreResult?.turnOverTickets?.count,
            recordsFiltered: fetchMoreResult?.turnOverTickets?.filteredCount,
            data: fetchMoreResult?.turnOverTickets?.data,
          };
          this.recordsFiltered = ticketData.recordsFiltered;
          this.recordsTotal = ticketData.recordsTotal;
          this.cardArray = this?.result?.turnOverTickets?.data;
          this.totalPages = Math.ceil(
            this?.result?.turnOverTickets?.count / this.pageLength
          );

          $("#srt").DataTable().clear().rows.add(ticketData.data).draw();
          this.closeModal("filteremployee");
        },
      });
    },
    async filterByStatus() {
      this.selectedStatus = parseInt(this.selectedStatus);
      this.statusSrt = parseInt(this.statusSrt);
      await this.refetch({
        filter: { ticket_status: this.selectedStatus },
      });

      await this.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          var ticketData = {
            recordsTotal: fetchMoreResult?.turnOverTickets?.count,
            recordsFiltered: fetchMoreResult?.turnOverTickets?.filteredCount,
            data: fetchMoreResult?.turnOverTickets?.data,
          };
          this.recordsFiltered = ticketData.recordsFiltered;
          this.recordsTotal = ticketData.recordsTotal;
          this.cardArray = this?.result?.turnOverTickets?.data;
          this.totalPages = Math.ceil(
            this?.result?.turnOverTickets?.count / this.pageLength
          );

          $("#srt").DataTable().clear().rows.add(ticketData.data).draw();
          this.closeModal("filterstatus");
        },
      });
    },
    async filterByPriority() {
      await this.refetch({
        filter: { priority: parseInt(this.selectedPriority) },
      });

      await this.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          var ticketData = {
            recordsTotal: fetchMoreResult?.turnOverTickets?.count,
            recordsFiltered: fetchMoreResult?.turnOverTickets?.filteredCount,
            data: fetchMoreResult?.turnOverTickets?.data,
          };
          this.recordsFiltered = ticketData.recordsFiltered;
          this.recordsTotal = ticketData.recordsTotal;
          this.cardArray = this?.result?.turnOverTickets?.data;
          this.totalPages = Math.ceil(
            this?.result?.turnOverTickets?.count / this.pageLength
          );

          $("#srt").DataTable().clear().rows.add(ticketData.data).draw();
          this.closeModal("filterpriority");
        },
      });
    },
    async filterByDepartment() {
      await this.refetch({
        filter: { department: parseInt(this.selectedDepartment) },
      });

      await this.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          var ticketData = {
            recordsTotal: fetchMoreResult?.turnOverTickets?.count,
            recordsFiltered: fetchMoreResult?.turnOverTickets?.filteredCount,
            data: fetchMoreResult?.turnOverTickets?.data,
          };
          this.recordsFiltered = ticketData.recordsFiltered;
          this.recordsTotal = ticketData.recordsTotal;
          this.cardArray = this?.result?.turnOverTickets?.data;
          this.totalPages = Math.ceil(
            this?.result?.turnOverTickets?.count / this.pageLength
          );

          $("#srt").DataTable().clear().rows.add(ticketData.data).draw();
          this.closeModal("filterdepartment");
        },
      });
    },
    async filterByCategory() {
      await this.refetch({
        filter: { category: parseInt(this.selectedCategory) },
      });

      await this.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          var ticketData = {
            recordsTotal: fetchMoreResult?.turnOverTickets?.count,
            recordsFiltered: fetchMoreResult?.turnOverTickets?.filteredCount,
            data: fetchMoreResult?.turnOverTickets?.data,
          };
          this.recordsFiltered = ticketData.recordsFiltered;
          this.recordsTotal = ticketData.recordsTotal;
          this.cardArray = this?.result?.turnOverTickets?.data;
          this.totalPages = Math.ceil(
            this?.result?.tickets?.count / this.pageLength
          );

          $("#srt").DataTable().clear().rows.add(ticketData.data).draw();
          this.closeModal("filtercategory");
        },
      });
    },
    async NoFilter() {
      await this.refetch({
        offset: 0,
        limit: 10,
        filter: null,
      });
      await this.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          var ticketData = {
            recordsTotal: fetchMoreResult?.turnOverTickets?.count,
            recordsFiltered: fetchMoreResult?.turnOverTickets?.filteredCount,
            data: fetchMoreResult?.turnOverTickets?.data,
          };
          this.recordsFiltered = ticketData.recordsFiltered;
          this.recordsTotal = ticketData.recordsTotal;
          this.cardArray = this?.result?.turnOverTickets?.data;
          this.totalPages = Math.ceil(
            this?.result?.turnOverTickets?.count / this.pageLength
          );

          $("#srt").DataTable().clear().rows.add(ticketData.data).draw();
          this.closeModal("filtercategory");
        },
      });
    },
  },

  async created() {
    this.fetchInprogTicketsCount();
    this.fetchOpenTicketsCount();
    this.fetchClosedTicketsCount();
    this.fetchOpenProjectCount();
    this.fetchInprogProjectCount();
    this.fetchCloseProjectCount();
    this.fetchRejectsProjectCount();
  },

  async mounted() {
    this.statusChange();
    this.role = localStorage.getItem("role");
    this.isAdmin = this.role === "Supervisor";
  },
};
</script>

<style>
.hazard-blink {
  animation: blink 3s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
</style>
