import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";

import Register from "../views/Register.vue";

import ResetPassword from "../views/resetPassword.vue";
import Cookies from "js-cookie";

// Listen for the 'beforeunload' event
window.addEventListener("beforeunload", (event) => {
  // Check if the tab is being closed
  if (event.clientX < 0 && event.clientY < 0) {
    // Clear token and user information when the tab is closed
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
});
const authGuard = (to, from, next) => {
  const isAuthenticated = localStorage.getItem("token") !== null;
  if (
    isAuthenticated &&
    (to.path === "/login" || to.path === "/" || to.path === "/forget")
  ) {
    next("/dashboard");
  } else if (isAuthenticated || to.path === "/login" || to.path === "/forget") {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  { path: "/", name: "login", component: Login },
  { path: "/register", name: "Register", component: Register },
  { path: "/login", component: Login },

  {
    path: "/forget",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/view-ticket/:id/:ticket_num",
    name: "View Ticket",
    component: () => import("../views/ViewTicket.vue"),
    beforeEnter: authGuard,
  },

  {
    path: "/change-request",
    name: "Change Request",
    component: () => import("../views/ChangeRequest.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/project-task",
    name: "Project Task",
    component: () => import("../views/ProjectTask.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/NotificationPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/past-turnover",
    name: "Past Turnover",
    component: () => import("../views/PastTurnover.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/projects-list",
    name: "Projects",
    component: () => import("../views/InitiatedProjects.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/ongoing-projects",
    name: "Ongoing Project",
    component: () => import("../views/OngoingProject.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/completed-projects",
    name: "Completed Project",
    component: () => import("../views/CompletedProject.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/view-projects",
    name: "View Project",
    component: () => import("../views/ViewProject.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/priority",
    name: "Ticket Priority",
    component: () => import("../views/Priority.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/status",
    name: "Ticket Status",
    component: () => import("../views/Status.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/project",
    name: "Project",
    component: () => import("../views/Project.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/my-project",
    name: "My Project",
    component: () => import("../views/MyProject.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/department",
    name: "Internal Department",
    component: () => import("../views/Department.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/category",
    name: "Ticket Category",
    component: () => import("../views/Category.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/daily-task",
    name: "Daily Task",
    component: () => import("../views/DailyTasks.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },

  {
    path: "/my-ticket",
    name: "Ticket",
    component: () => import("../views/MyTicket.vue"),
    beforeEnter: authGuard,
    meta: { title: "Ticket" },
  },

  {
    path: "/assigned-ticket",
    name: "Assigned ticket",
    component: () => import("../views/AssignedTicket.vue"),
    beforeEnter: authGuard,
    meta: { title: "Assigned Ticket" },
  },

  {
    path: "/profile",
    name: "My Profile",
    component: () => import("../views/Profile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Profile" },
  },

  {
    path: "/all-tickets",
    name: "All Tickets",
    component: () => import("../views/AllTickets.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authGuard);

export default router;
