import { createApp, provide, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client/core";

import { DefaultApolloClient } from "@vue/apollo-composable";
import { onError } from "@apollo/client/link/error";
import { createApolloProvider } from "@vue/apollo-option";
import { setContext } from "apollo-link-context";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/css/sb-admin-2.min.css";
// import "@/assets/css/all.min.css";
// /* import jQuery/$ for plugins */
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

require("@/assets/vendor/jquery/jquery.min.js");
require("@/assets/vendor/bootstrap/js/bootstrap.bundle.min.js");
require("@/assets/vendor/jquery-easing/jquery.easing.min.js");
import "@/assets/css/sb-admin-2.min.css";
require("@/assets/css/fonts.min.css");

const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: "https://ticketing.innovativetoll.com/api",
  credentials: "include",
});

// Cache implementation
const cache = new InMemoryCache();
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // );
      // if (message === "Unauthorized") {
      //   console.log("Unauthorized");
      //   // store.dispatch("logout");
      // }
      console.log(message, "message");
      if (message === "Unauthenticated") {
        localStorage.clear();
        router.push("/login");
        // store.dispatch("logout");
      }
    });

  // if (networkError) console.log(`[Network error]: ${networkError}`);
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from localstorage if it exists
  const token = localStorage.getItem("token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : null,
    },
  };
});
// Create the apollo client
const apolloClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  link: from([errorLink, authLink.concat(httpLink)]),
  // link: httpLink,
  cache,
  // credentials: "include",
  // credentials: "include",
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
  data() {
    return {
      inactivityThreshold: 1800000, // 30 minutes

      inactivityTimer: null,
    };
  },
  methods: {
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = setTimeout(() => {
        // Perform actions when user is inactive
        console.log("User is inactive");
        if (
          confirm(
            "You will be logged out due to inactivity. Do you want to stay logged in?"
          )
        ) {
          console.log("User is active");
        } else {
          // check current route
          if (router.currentRoute.value.path !== "/login") {
            localStorage.clear();
            router.push("/login"); // Redirect to login page
          }
          // logoutUser();
        }
        // For example, you could display a message, log them out, etc.
      }, this.inactivityThreshold);
    },
    setupEventListeners() {
      document.addEventListener("mousemove", this.resetInactivityTimer);
      document.addEventListener("keypress", this.resetInactivityTimer);
      document.addEventListener("click", this.resetInactivityTimer);
    },
    removeEventListeners() {
      document.removeEventListener("mousemove", this.resetInactivityTimer);
      document.removeEventListener("keypress", this.resetInactivityTimer);
      document.removeEventListener("click", this.resetInactivityTimer);
    },
  },
  mounted() {
    this.setupEventListeners();
    this.resetInactivityTimer();
  },
  beforeDestroy() {
    this.removeEventListeners();
    clearTimeout(this.inactivityTimer);
  },
  data() {
    return {
      inactivityThreshold: 1800000, // 30 minutes

      inactivityTimer: null,
    };
  },
  methods: {
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = setTimeout(() => {
        // Perform actions when user is inactive
        console.log("User is inactive");
        if (
          confirm(
            "You will be logged out due to inactivity. Do you want to stay logged in?"
          )
        ) {
          console.log("User is active");
        } else {
          // check current route
          if (router.currentRoute.value.path !== "/login") {
            localStorage.clear();
            router.push("/login"); // Redirect to login page
          }
          // logoutUser();
        }
        // For example, you could display a message, log them out, etc.
      }, this.inactivityThreshold);
    },
    setupEventListeners() {
      document.addEventListener("mousemove", this.resetInactivityTimer);
      document.addEventListener("keypress", this.resetInactivityTimer);
      document.addEventListener("click", this.resetInactivityTimer);
    },
    removeEventListeners() {
      document.removeEventListener("mousemove", this.resetInactivityTimer);
      document.removeEventListener("keypress", this.resetInactivityTimer);
      document.removeEventListener("click", this.resetInactivityTimer);
    },
  },
  mounted() {
    this.setupEventListeners();
    this.resetInactivityTimer();
  },
  beforeDestroy() {
    this.removeEventListeners();
    clearTimeout(this.inactivityTimer);
  },
})
  .use(router)
  .use(store)
  .use(apolloProvider)
  .use(VueSweetalert2)
  .mount("#app");
